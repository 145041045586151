<template>
  <div>
    <CCard>
      <CCardHeader class="bg-warning text-black"
        >สรุปยอดค้างชำระค่าบำรุงการศึกษา โรงเรียน{{ userData.school_name }}
      </CCardHeader>
      <CCardBody v-if="rows">
        <CContainer>
          <CRow>
            <CCol lg="12" style="margin-bottom: 20px">
              <div class="text-right alert alert-danger" v-if="sumAmount > 0">
                <h3>
                  ยอดค้างชำระ: {{ Number(sumAmount).toLocaleString() }} บาท
                </h3>
              </div>
              <div class="text-right alert alert-success" v-else>
                <h3>
                  ยอดค้างชำระ: {{ Number(sumAmount).toLocaleString() }} บาท
                </h3>
              </div>
            </CCol>
          </CRow>
        </CContainer>

        <vue-good-table
          :columns="columns"
          :rows="rows"
          :search-options="{ enabled: true }"
          :sort-options="{
            enabled: true,
            initialSortBy: { field: 'level_fullName', type: 'asc' },
          }"
          :pagination-options="{ enabled: true, mode: 'page', perPage: 10 }"
        >
          >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'sumPendingByLevel'">
              {{ Number(props.row.sumPendingByLevel).toLocaleString() }}
            </span>
          </template>
        </vue-good-table>
      </CCardBody>
      <CCardFooter class="text-right">
        <button class="btn btn-warning" @click="onExport" v-if="allInvoice">
          Export invoice ทั้งหมด
        </button>
      </CCardFooter>
    </CCard>

    <!--MODAL EDIT---------------------------------------------------------------------------->
    <form
      id="formUpdatePayment"
      v-on:submit.prevent="updatePayment"
      method="POST"
    >
      <CModal
        :show.sync="darkModal"
        :no-close-on-backdrop="true"
        :centered="true"
        title="Modal title 2"
        size="lg"
        color="dark"
      >
        <CRow>
          <CCol lg="6" v-if="selectedInvoice"
            ><div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Invoice No:
              </label>
              <input
                type="text"
                name="invoiceId"
                class="form-control"
                readonly
                :value="selectedInvoice.invoice_id"
              /></div
          ></CCol>
          <CCol lg="6" v-if="userData"
            ><div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                เลขประจำตัวผู้แจ้ง:
              </label>
              <input
                type="text"
                name="txtStdId"
                class="form-control"
                readonly
                v-model="userData.user_id"
              /></div
          ></CCol>
        </CRow>
        <CRow v-if="selectedInvoice">
          <CCol lg="3"> เลขประจำตัวนักเรียน: </CCol>
          <CCol lg="9">
            {{ selectedInvoice.invoice_std_id }}
          </CCol>
          <CCol lg="3"> รายละเอียด: </CCol>
          <CCol lg="9">
            {{ selectedInvoice.invoice_content }}
          </CCol>
          <CCol lg="3"> ยอดต้องชำระ: </CCol>
          <CCol lg="9">
            {{ Number(selectedInvoice.invoice_amount).toLocaleString() }} บาท
          </CCol>
          <CCol lg="3"> วันเวลาออก Invoice: </CCol>
          <CCol lg="9">
            {{ selectedInvoice.invoice_created }}
          </CCol>
          <CCol lg="6" v-if="paymentInfo">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              สถานะ:
            </label>
            <select
              name="invoiceStatus"
              class="form-control"
              v-model="selectedInvoice.invoice_status"
            >
              <option
                v-for="payment in paymentInfo"
                :key="payment.id"
                :value="payment.payment_id"
              >
                {{ payment.paymant_name }}
              </option>
            </select>
          </CCol>
        </CRow>

        <template #header>
          <h3 class="modal-title">ชำระเงิน</h3>
          <CButtonClose @click="closeModal" class="text-white" />
        </template>
        <template #footer>
          <CButton @click="closeModal" color="danger">Discard</CButton>
          <button type="submit" class="btn btn-success">Save</button>
        </template>
      </CModal>
    </form>

    <!--MODAL FILTER BY STD---------------------------------------------------------------------------->
    <CModal
      :show.sync="modalFilterByStd"
      :no-close-on-backdrop="true"
      :centered="true"
      title="Modal title 2"
      size="lg"
      color="dark"
    >
      <CRow style="margin-bottom: 20px">
        <CCol lg="6">
          <div class="relative w-full mb-3" v-if="userData">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              เลขประจำตัวนักเรียน:
            </label>
            <input
              type="number"
              name="lowerLimit"
              class="form-control"
              aria-label="Username"
              aria-describedby="basic-addon1"
              v-model="selectedStudent"
            />
          </div>
        </CCol>
      </CRow>

      <template #header>
        <h3 class="modal-title">กรองจากเลขประจำตัวนักเรียน</h3>
        <CButtonClose @click="modalFilterByStd = false" class="text-white" />
      </template>
      <template #footer>
        <button
          type="button"
          class="btn btn-success"
          @click="searchInvoiceByStd"
        >
          filter
        </button>
      </template>
    </CModal>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import XLSX from "xlsx"; // import xlsx
import { CWidgetStatsE } from "@coreui/vue";

export default {
  components: {
    Datepicker,
    vSelect,
    VueGoodTable,
    XLSX,
    CWidgetStatsE,
  },
  data() {
    return {
      userData: this.$cookies.get("user"),
      invoiceInfo: null,
      paymentInfo: null,
      selectedInvoice: null,
      selectedStudent: null,
      responseContent: null,
      showModal: false,
      modalSuccess: null,
      newCiteria: null,
      darkModal: false,
      columns: [
        {
          label: "ระดับชั้น",
          field: "level_fullName",
          type: "text",
        },
        {
          label: "ยอดค้างชำระรวม (บาท)",
          field: "sumPendingByLevel",
          type: "number",
        },
        {
          label: "Actions",
          field: "actions",
          width: "10%",
        },
      ],
      rows: null,
      rowsExcel: null,
      sumAmount: null,
      uploadFiles: [],
      activeTab: 0,
      invoiceStart: null,
      invoiceStop: null,
      modalFilterByInvoice: false,
      modalFilterByStd: false,
      allInvoice: null,
      downloadState: false,
    };
  },
  mounted() {
    //FETCH PENDING SUMMARY
    this.axios
      .get(
        this.$hostUrl +
          "php_action/invoiceAPI.php?txtAction=getPenddingInvoice&schoolCode=" +
          this.userData.school_code
      )
      .then((response) => {
        //console.log(response.data);
        this.rows = response.data.mainData;
        this.sumAmount = response.data.sumPending;
      });
  },
  methods: {
    searchInvoice(event) {
      const form = document.getElementById(event.target.id);
      const formData = new FormData(form);
      formData.append("txtAction", "getInvoice");
      formData.append("qSchool", this.userData.school_code);
      this.axios
        .post(this.$hostUrl + "php_action/invoiceAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          this.rows = response.data.mainData;
          this.sumAmount = response.data.sumPending;
          this.rowsExcel = response.data.excelData;
        })
        .finally(() => {});
    },
    updateInvoiceTable(start, stop) {
      const formData = new FormData();
      formData.append("txtAction", "getInvoice");
      formData.append("qSchool", this.userData.school_code);
      formData.append("lowerLimit", start);
      formData.append("upperLimit", stop);
      this.axios
        .post(this.$hostUrl + "php_action/invoiceAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          this.rows = response.data.mainData;
          this.sumAmount = response.data.sumPending;
        })
        .finally(() => {});
    },
    toggleModal: function () {
      this.showModal = !this.showModal;
      location.reload();
    },
    openModal(data) {
      this.uploadFiles = [];
      this.darkModal = true;
      //FETCH SELECTED INVOICE
      this.axios
        .get(
          this.$hostUrl +
            "php_action/invoiceAPI.php?txtAction=getInvoice&qInvoice=" +
            data
        )
        .then((response) => {
          //console.log(response.data);
          this.selectedInvoice = response.data.mainData;
        })
        .finally(() => {});
    },
    closeModal() {
      //location.reload();
      this.darkModal = false;
    },
    updatePayment(event) {
      const form = document.getElementById(event.target.id);
      const formData = new FormData(form);
      formData.append("txtAction", "update");
      formData.append("userId", this.userData.user_id);
      this.axios
        .post(this.$hostUrl + "php_action/invoiceAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.updateState == "success") {
            this.responseContent = "บันทึกสำเร็จ";
            alert(this.responseContent);
            this.updateInvoiceTable(this.invoiceStart, this.invoiceStop);
          } else {
            this.responseContent = response.data.error;
            alert(this.responseContent);
            //location.reload();
          }
        })
        .finally(() => {});
    },
    deleteInvoice(data) {
      if (confirm("ต้องการลบใบแจ้งหนี้ " + data + " ใช่หรือไม่")) {
        const formData = new FormData();
        formData.append("txtAction", "delete");
        formData.append("invoiceId", data);
        formData.append("userId", this.userData.user_id);
        this.axios
          .post(this.$hostUrl + "php_action/invoiceAPI.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log(response.data);
            if (response.data.deleteState == "success") {
              this.responseContent = "บันทึกสำเร็จ";
              //alert(this.responseContent);
              this.updateInvoiceTable(this.invoiceStart, this.invoiceStop);
            } else {
              this.responseContent = response.data.error;
              alert(this.responseContent);
              //location.reload();
            }
          })
          .finally(() => {});
      } else {
        return false;
      }
    },
    handleImages(files) {
      this.uploadFiles = files;
      //console.log(this.uploadFiles);
    },
    onExport() {
      //console.log(this.rowsExcel);
      const dataWS = XLSX.utils.json_to_sheet(this.allInvoice.mainData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS);
      XLSX.writeFile(wb, "export.xlsx");
    },
    searchInvoiceByStd() {
      //FETCH INVOICE INFO BY STD
      //alert(this.selectedStudent);
      this.axios
        .get(
          this.$hostUrl +
            "php_action/invoiceAPI.php?txtAction=getInvoice&qStd=" +
            this.selectedStudent
        )
        .then((response) => {
          console.log(response.data);
          this.rows = response.data.mainData;
          this.sumAmount = response.data.sumPending;
        })
        .finally(() => {});
    },
  },
};
</script>
<style scoped>
.ui-input-text {
  border-color: transparent;
}
</style>
