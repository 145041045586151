<template>
  <CCard>
    <CCardHeader class="bg-pink text-white"
      >สรุปรายการใบแจ้งหนี้ที่ชำระแล้วของโรงเรียน{{ userData.school_name }}
      <div class="text-right"></div>
    </CCardHeader>

    <CCardBody v-if="rows">
      <form
        id="formSearchPaidInvoice"
        v-on:submit.prevent="searchPaidInvoice"
        method="POST"
      >
        <CRow style="margin-bottom: 20px">
          <CCol lg="6">
            <div class="relative w-full mb-3" v-if="userData">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                จากวันที่:
              </label>
              <datepicker
                name="dateStart"
                input-class="form-control bg-white"
                :required="true"
                format="yyyy-MM-dd"
                placeholder="คลิ๊ก"
                v-model="startDate"
              ></datepicker>
            </div>
          </CCol>
          <CCol lg="6">
            <div class="relative w-full mb-3" v-if="userData">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                จนถึงวันที่:
              </label>
              <datepicker
                name="dateEnd"
                input-class="form-control bg-white"
                :required="true"
                format="yyyy-MM-dd"
                placeholder="คลิ๊ก"
                v-model="endDate"
              ></datepicker>
            </div>
          </CCol>
          <CCol lg="12" class="text-right" style="margin-bottom:20px">
            <button class="btn btn-primary">search</button>
          </CCol>
          <CCol lg="6" class="text-right">
            <CCard >
              <CCardFooter class="text-white bg-pink">
                ชำระแล้ว<br />
                <span style="font-size:29px">{{ rows.length }} รายการ</span></CCardFooter
              >
            </CCard>
          </CCol>
          <CCol lg="6" class="text-right">
            <CCard >
              <CCardFooter class="text-white bg-pink">
                รวมเป็นเงิน<br />
                <span style="font-size:29px">{{Number(sumAmount).toLocaleString()}} บาท</span></CCardFooter
              >
            </CCard>
          </CCol>
        </CRow>
      </form>
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :search-options="{ enabled: true }"
        :sort-options="{
          enabled: true,
          initialSortBy: { field: 'invoice_id', type: 'asc' },
        }"
        :pagination-options="{ enabled: true, mode: 'page', perPage: 10 }"
      >
        >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'actions'">
            <div v-if="(userData.user_level).search('budgetOfficer')>=0">
              <button
                class="btn btn-warning btn-block"
                type="button"
                @click="openModal(props.row.invoice_id)"
              >
                edit
              </button>
            </div>
          </span>
          <span v-else-if="props.column.field == 'invoice_status'">
            <CBadge v-if="props.row.invoice_status == 1" color="danger">{{
              props.row.paymant_name
            }}</CBadge>
            <CBadge v-else-if="props.row.invoice_status == 2" color="light">{{
              props.row.paymant_name
            }}</CBadge>
            <CBadge v-else-if="props.row.invoice_status == 3" color="success">{{
              props.row.paymant_name
            }}</CBadge>
            <CBadge v-else-if="props.row.invoice_status == 4" color="info">{{
              props.row.paymant_name
            }}</CBadge>
          </span>
          <span v-else-if="props.column.field == 'invoice_amount'">
            {{ Number(props.row.invoice_amount).toLocaleString() }}
          </span>
        </template>
      </vue-good-table>
    </CCardBody>
    <CCardFooter class="text-right"> </CCardFooter>

    <!--MODAL SECTION---------------------------------------------------------------------------->
    <form
      id="formUpdatePayment"
      v-on:submit.prevent="updatePayment"
      method="POST"
    >
      <CModal
        :show.sync="darkModal"
        :no-close-on-backdrop="true"
        :centered="true"
        title="Modal title 2"
        size="lg"
        color="dark"
      >
        <CRow>
          <CCol lg="6" v-if="selectedInvoice"
            ><div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Invoice No:
              </label>
              <input
                type="text"
                name="invoiceId"
                class="form-control"
                readonly
                :value="selectedInvoice.invoice_id"
              /></div
          ></CCol>
          <CCol lg="6" v-if="userData"
            ><div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                เลขประจำตัวผู้แจ้ง:
              </label>
              <input
                type="text"
                name="txtStdId"
                class="form-control"
                readonly
                v-model="userData.user_id"
              /></div
          ></CCol>
        </CRow>
        <CRow v-if="selectedInvoice">
          <CCol lg="3"> เลขประจำตัวนักเรียน: </CCol>
          <CCol lg="9">
            {{ selectedInvoice.invoice_std_id }}
          </CCol>
          <CCol lg="3"> รายละเอียด: </CCol>
          <CCol lg="9">
            {{ selectedInvoice.invoice_content }}
          </CCol>
          <CCol lg="3"> ยอดต้องชำระ: </CCol>
          <CCol lg="9">
            {{ Number(selectedInvoice.invoice_amount).toLocaleString() }} บาท
          </CCol>
          <CCol lg="3"> วันเวลาออก Invoice: </CCol>
          <CCol lg="9">
            {{ selectedInvoice.invoice_created }}
          </CCol>
          <CCol lg="6" v-if="paymentInfo">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              สถานะ:
            </label>
            <select
              name="invoiceStatus"
              class="form-control"
              v-model="selectedInvoice.invoice_status"
            >
              <option
                v-for="payment in paymentInfo"
                :key="payment.id"
                :value="payment.payment_id"
              >
                {{ payment.paymant_name }}
              </option>
            </select>
          </CCol>
        </CRow>

        <template #header>
          <h3 class="modal-title">ชำระเงิน</h3>
          <CButtonClose @click="closeModal" class="text-white" />
        </template>
        <template #footer>
          <CButton @click="closeModal" color="danger">Discard</CButton>
          <button type="submit" class="btn btn-success">Save</button>
        </template>
      </CModal>
    </form>
  </CCard>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";


export default {
  components: {
    Datepicker,
    vSelect,
    VueGoodTable,
  },
  data() {
    return {
      userData: this.$cookies.get("user"),
      invoiceInfo: null,
      paymentInfo: null,
      selectedInvoice: null,
      responseContent: null,
      showModal: false,
      modalSuccess: null,
      newCiteria: null,
      darkModal: false,
      columns: [
        {
          label: "#",
          field: "invoice_id",
          type: "number",
        },
        {
          label: "เลขประจำตัวนักเรียน",
          field: "invoice_std_id",
        },

        {
          label: "รายละเอียด",
          field: "invoice_content",
        },
        {
          label: "จำนวน (บาท)",
          field: "invoice_amount",
          type: "number",
        },
        {
          label: "สถานะ",
          field: "invoice_status",
          type: "html",
        },
        {
          label: "สร้างรายการเมื่อ",
          field: "invoice_created",
        },
        {
          label: "แก้ไขเมื่อ",
          field: "invoice_updated",
        },
        {
          label: "แก้ไขโดย",
          field: "user_fname",
        },
        {
          label: "Actions",
          field: "actions",
          width: "10%",
        },
      ],
      rows: null,
      sumAmount: null,
      uploadFiles: [],
      activeTab: 0,
      invoiceStart: null,
      invoiceStop: null,
      startDate: null,
      endDate: null,
    };
  },
  mounted() {
    //console.log(this.userData);

    //FETCH PAYMENT INFO
    this.axios
      .get(this.$hostUrl + "php_action/invoiceAPI.php?txtAction=getPaymentList")
      .then((response) => {
        //console.log(response.data);
        this.paymentInfo = response.data.mainData;
      })
      .finally(() => {});

    //FETCH INVOICE INFO
    this.axios
      .get(
        this.$hostUrl +
          "php_action/invoiceAPI.php?txtAction=getInvoice&qSchool=" +
          this.userData.school_code +
          "&lowerLimit=0&upperLimit=0"
      )
      .then((response) => {
        //console.log(response.data);
        this.rows = response.data.mainData;
        this.sumAmount = response.data.sumPending;
      })
      .finally(() => {});
  },
  methods: {
    searchPaidInvoice(event) {
      if (this.startDate == null || this.endDate == null) {
        alert("เลือกวันที่ให้ถูกต้อง");
        return false;
      }
      const form = document.getElementById(event.target.id);
      const formData = new FormData(form);
      formData.append("txtAction", "getInvoiceByDate");
      formData.append("qSchool", this.userData.school_code);
      this.axios
        .post(this.$hostUrl + "php_action/invoiceAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log(response.data);
          this.rows = response.data.mainData;
          this.sumAmount = 0;
          this.rows.forEach((element) => {
            this.sumAmount += parseFloat(element.invoice_amount);
          });

          //console.log(this.sumAmount);
        })
        .finally(() => {});
    },
    updateInvoiceTable(start, stop) {
      const formData = new FormData();
      formData.append("txtAction", "getInvoice");
      formData.append("qSchool", this.userData.school_code);
      formData.append("lowerLimit", start);
      formData.append("upperLimit", stop);
      this.axios
        .post(this.$hostUrl + "php_action/invoiceAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          this.rows = response.data.mainData;
          this.sumAmount = response.data.sumPending;
        })
        .finally(() => {});
    },
    toggleModal: function () {
      this.showModal = !this.showModal;
      location.reload();
    },
    openModal(data) {
      this.uploadFiles = [];
      this.darkModal = true;
      //FETCH SELECTED INVOICE
      this.axios
        .get(
          this.$hostUrl +
            "php_action/invoiceAPI.php?txtAction=getInvoice&qInvoice=" +
            data
        )
        .then((response) => {
          //console.log(response.data);
          this.selectedInvoice = response.data.mainData;
        })
        .finally(() => {});
    },
    closeModal() {
      //location.reload();
      this.darkModal = false;
    },
    updatePayment(event) {
      const form = document.getElementById(event.target.id);
      const formData = new FormData(form);
      formData.append("txtAction", "update");
      formData.append("userId", this.userData.user_id);
      this.axios
        .post(this.$hostUrl + "php_action/invoiceAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.updateState == "success") {
            this.responseContent = "บันทึกสำเร็จ";
            alert(this.responseContent);
            this.updateInvoiceTable(this.invoiceStart, this.invoiceStop);
          } else {
            this.responseContent = response.data.error;
            alert(this.responseContent);
            //location.reload();
          }
        })
        .finally(() => {});
    },
    handleImages(files) {
      this.uploadFiles = files;
      //console.log(this.uploadFiles);
    },
  },
};
</script>
<style scoped>
.ui-input-text {
  border-color: transparent;
}
</style>
