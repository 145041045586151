<template>
  <div>
    <CCard>
      <CCardHeader class="bg-warning text-white"
        >สรุปรายการของโรงเรียน{{ userData.school_name }}
      </CCardHeader>

      <CCardBody v-if="rows">
        <CContainer>
          <CRow>
            <CCol
              lg="6"
              class="text-right alert-info"
              style="margin-bottom: 20px"
              v-if="allInvoice"
            >
              ยอดเรียกเก็บเงิน:
              {{ Number(allInvoice.sumTotal).toLocaleString() }} บาท
            </CCol>
            <CCol
              lg="6"
              class="text-right alert-danger"
              style="margin-bottom: 20px"
              v-if="allInvoice"
            >
              ยอดรวมที่ค้างชำระ:
              {{ Number(allInvoice.netPending).toLocaleString() }} บาท
            </CCol>

            <CCol lg="6" style="margin-bottom: 20px">
              <button
                class="btn btn-secondary btn-block"
                @click="modalFilterByInvoice = true"
              >
                <span class="iconify" data-icon="cil:filter"></span
                >กรองจากเลขที่ใบแจ้งหนี้
              </button>
            </CCol>
            <CCol lg="6" style="margin-bottom: 20px">
              <button
                class="btn btn-primary btn-block"
                @click="modalFilterByStd = true"
              >
                กรองจากเลขประจำตัวนักเรียน
              </button>
            </CCol>
            <CCol lg="12" style="margin-bottom: 20px">
              <div class="text-right">
                <h3 v-if="sumAmount > 0" class="text-black">
                  ยอดค้างชำระ: {{ Number(sumAmount).toLocaleString() }} บาท
                </h3>
                <h3 v-else class="text-success">
                  ยอดค้างชำระ: {{ Number(sumAmount).toLocaleString() }} บาท
                </h3>
              </div>
            </CCol>
          </CRow>
        </CContainer>

        <vue-good-table
          :columns="columns"
          :rows="rows"
          :search-options="{ enabled: true }"
          :sort-options="{
            enabled: true,
            initialSortBy: { field: 'invoice_id', type: 'asc' },
          }"
          :pagination-options="{ enabled: true, mode: 'page', perPage: 10 }"
        >
          >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'actions'">
              <div v-if="userData.user_level.search('budgetOfficer') >= 0">
                <button
                  class="btn btn-warning btn-block"
                  type="button"
                  @click="openModal(props.row.invoice_id)"
                >
                  edit
                </button>
                <button
                  class="btn btn-danger btn-block"
                  type="button"
                  @click="deleteInvoice(props.row.invoice_id)"
                >
                  delete
                </button>
              </div>
            </span>
            <span v-else-if="props.column.field == 'invoice_status'">
              <CBadge v-if="props.row.invoice_status == 1" color="danger">{{
                props.row.paymant_name
              }}</CBadge>
              <CBadge v-else-if="props.row.invoice_status == 2" color="light">{{
                props.row.paymant_name
              }}</CBadge>
              <CBadge
                v-else-if="props.row.invoice_status == 3"
                color="success"
                >{{ props.row.paymant_name }}</CBadge
              >
              <CBadge v-else-if="props.row.invoice_status == 4" color="info">{{
                props.row.paymant_name
              }}</CBadge>
            </span>
            <span v-else-if="props.column.field == 'invoice_amount'">
              {{ Number(props.row.invoice_amount).toLocaleString() }}
            </span>
            <span v-else-if="props.column.field == 'stdClass'">
              {{ props.row.level_abv_name }}/{{ props.row.std_class }}
            </span>
          </template>
        </vue-good-table>
      </CCardBody>
      <CCardFooter class="text-right">
        <button
          class="btn btn-warning"
          @click="onExport(7)"
          v-if="allInvoice"
          style="margin: 5px"
        >
          Export ม.1
        </button>
        <button
          class="btn btn-warning"
          @click="onExport(8)"
          v-if="allInvoice"
          style="margin: 5px"
        >
          Export ม.2
        </button>
        <button
          class="btn btn-warning"
          @click="onExport(9)"
          v-if="allInvoice"
          style="margin: 5px"
        >
          Export ม.3
        </button>
        <button
          class="btn btn-warning"
          @click="onExport(10)"
          v-if="allInvoice"
          style="margin: 5px"
        >
          Export ม.4
        </button>
        <button
          class="btn btn-warning"
          @click="onExport(11)"
          v-if="allInvoice"
          style="margin: 5px"
        >
          Export ม.5
        </button>
        <button
          class="btn btn-warning"
          @click="onExport(12)"
          v-if="allInvoice"
          style="margin: 5px"
        >
          Export ม.6
        </button>
      </CCardFooter>
    </CCard>

    <!--MODAL EDIT---------------------------------------------------------------------------->
    <form
      id="formUpdatePayment"
      v-on:submit.prevent="updatePayment"
      method="POST"
    >
      <CModal
        :show.sync="darkModal"
        :no-close-on-backdrop="true"
        :centered="true"
        title="Modal title 2"
        size="lg"
        color="dark"
      >
        <CRow>
          <CCol lg="6" v-if="selectedInvoice"
            ><div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Invoice No:
              </label>
              <input
                type="text"
                name="invoiceId"
                class="form-control"
                readonly
                :value="selectedInvoice.invoice_id"
              /></div
          ></CCol>
          <CCol lg="6" v-if="userData"
            ><div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                เลขประจำตัวผู้แจ้ง:
              </label>
              <input
                type="text"
                name="userId"
                class="form-control"
                readonly
                v-model="userData.user_id"
              /></div
          ></CCol>
        </CRow>
        <CRow v-if="selectedInvoice">
          <CCol lg="3"> เลขประจำตัวนักเรียน: </CCol>
          <CCol lg="9">
            {{ selectedInvoice.invoice_std_id }}
          </CCol>
          <CCol lg="3"> รายละเอียด: </CCol>
          <CCol lg="9">
            {{ selectedInvoice.invoice_content }}
          </CCol>
          <CCol lg="3"> ยอดต้องชำระ: </CCol>
          <CCol lg="9">
            {{ Number(selectedInvoice.invoice_amount).toLocaleString() }} บาท
          </CCol>
          <CCol lg="3"> วันเวลาออก Invoice: </CCol>
          <CCol lg="9">
            {{ selectedInvoice.invoice_created }}
          </CCol>
          <CCol lg="6" v-if="paymentInfo">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              สถานะ:
            </label>
            <select
              name="invoiceStatus"
              class="form-control"
              v-model="selectedInvoice.invoice_status"
            >
              <option
                v-for="payment in paymentInfo"
                :key="payment.id"
                :value="payment.payment_id"
              >
                {{ payment.paymant_name }}
              </option>
            </select>
          </CCol>
        </CRow>

        <template #header>
          <h3 class="modal-title">ชำระเงิน</h3>
          <CButtonClose @click="closeModal" class="text-white" />
        </template>
        <template #footer>
          <CButton @click="closeModal" color="danger">Discard</CButton>
          <button type="submit" class="btn btn-success">Save</button>
        </template>
      </CModal>
    </form>
    <!--MODAL FILTER BY INVOICE---------------------------------------------------------------------------->
    <form
      id="formSearchInvoice"
      v-on:submit.prevent="searchInvoice"
      method="POST"
    >
      <CModal
        :show.sync="modalFilterByInvoice"
        :no-close-on-backdrop="true"
        :centered="true"
        title="Modal title 2"
        size="lg"
        color="dark"
      >
        <CRow style="margin-bottom: 20px">
          <CCol lg="6">
            <div class="relative w-full mb-3" v-if="userData">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                จาก Invoice เลขที่:
              </label>
              <input
                type="number"
                name="lowerLimit"
                class="form-control"
                aria-label="Username"
                aria-describedby="basic-addon1"
                required
                v-model="invoiceStart"
              />
            </div>
          </CCol>
          <CCol lg="6">
            <div class="relative w-full mb-3" v-if="userData">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                จนถึง Invoice เลขที่:
              </label>
              <input
                type="number"
                name="upperLimit"
                class="form-control"
                aria-label="Username"
                aria-describedby="basic-addon1"
                required
                v-model="invoiceStop"
              />
            </div>
          </CCol>
        </CRow>

        <template #header>
          <h3 class="modal-title">กรองจากเลขที่ใบแจ้งหนี้</h3>
          <CButtonClose
            @click="modalFilterByInvoice = false"
            class="text-white"
          />
        </template>
        <template #footer>
          <button type="submit" class="btn btn-success">filter</button>
        </template>
      </CModal>
    </form>

    <!--MODAL FILTER BY STD---------------------------------------------------------------------------->

    <CModal
      :show.sync="modalFilterByStd"
      :no-close-on-backdrop="true"
      :centered="true"
      title="Modal title 2"
      size="lg"
      color="dark"
    >
      <CRow style="margin-bottom: 20px">
        <CCol lg="6">
          <div class="relative w-full mb-3" v-if="userData">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              เลขประจำตัวนักเรียน:
            </label>
            <input
              type="number"
              name="lowerLimit"
              class="form-control"
              aria-label="Username"
              aria-describedby="basic-addon1"
              v-model="selectedStudent"
            />
          </div>
        </CCol>
      </CRow>

      <template #header>
        <h3 class="modal-title">กรองจากเลขประจำตัวนักเรียน</h3>
        <CButtonClose @click="modalFilterByStd = false" class="text-white" />
      </template>
      <template #footer>
        <button
          type="button"
          class="btn btn-success"
          @click="searchInvoiceByStd"
        >
          filter
        </button>
      </template>
    </CModal>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";

import XLSX from "xlsx"; // import xlsx
import { CWidgetStatsE } from "@coreui/vue";

export default {
  components: {
    Datepicker,
    vSelect,
    VueGoodTable,
    XLSX,
    CWidgetStatsE,
  },
  data() {
    return {
      userData: this.$cookies.get("user"),
      invoiceInfo: null,
      paymentInfo: null,
      selectedInvoice: null,
      selectedStudent: null,
      responseContent: null,
      showModal: false,
      modalSuccess: null,
      newCiteria: null,
      darkModal: false,
      columns: [
        {
          label: "#",
          field: "invoice_id",
          type: "number",
        },
        {
          label: "เลขประจำตัวนักเรียน",
          field: "invoice_std_id",
        },
        {
          label: "ชื่อ",
          field: "std_name",
        },
        {
          label: "นามสกุล",
          field: "std_surname",
        },

        {
          label: "ห้อง",
          field: "stdClass",
        },

        {
          label: "รายละเอียด",
          field: "invoice_content",
        },
        {
          label: "จำนวน (บาท)",
          field: "invoice_amount",
          type: "number",
        },
        {
          label: "สถานะ",
          field: "invoice_status",
          type: "html",
        },
        {
          label: "สร้างรายการเมื่อ",
          field: "invoice_created",
        },
        {
          label: "แก้ไขเมื่อ",
          field: "invoice_updated",
        },
        {
          label: "แก้ไขโดย",
          field: "user_fname",
        },
        {
          label: "Actions",
          field: "actions",
          width: "10%",
        },
      ],
      rows: null,
      rowsExcel: null,
      sumAmount: null,
      uploadFiles: [],
      activeTab: 0,
      invoiceStart: null,
      invoiceStop: null,
      modalFilterByInvoice: false,
      modalFilterByStd: false,
      allInvoice: null,
      downloadState: false,
    };
  },
  mounted() {
    //console.log(this.userData);

    //FETCH ALL INVOICE
    let formData = new FormData();
    formData.append("txtAction", "getAllInvoiceData");
    formData.append("schoolCode", this.userData.user_address);
    formData.append("level", 7);
    this.axios
      .post(this.$hostUrl + "php_action/invoiceAPI.php", formData)
      .then((response) => {
        //console.log(response.data);
        this.allInvoice = response.data;
        this.invoiceStop = this.allInvoice.mainData[0].invoice_id;
        this.invoiceStart = this.invoiceStop - 2000;
      });

    //FETCH PAYMENT INFO
    this.axios
      .get(this.$hostUrl + "php_action/invoiceAPI.php?txtAction=getPaymentList")
      .then((response) => {
        //console.log(response.data);
        this.paymentInfo = response.data.mainData;
      });

    //FETCH INVOICE INFO
    this.axios
      .get(
        this.$hostUrl +
          "php_action/invoiceAPI.php?txtAction=getInvoice&qSchool=" +
          this.userData.school_code +
          "&lowerLimit=0&upperLimit=0"
      )
      .then((response) => {
        //console.log(response.data);
        this.rows = response.data.mainData;
        this.sumAmount = response.data.sumPending;
      })
      .finally(() => {});
  },
  methods: {
    searchInvoice(event) {
      const form = document.getElementById(event.target.id);
      const formData = new FormData(form);
      formData.append("txtAction", "getInvoice");
      formData.append("qSchool", this.userData.school_code);
      this.axios
        .post(this.$hostUrl + "php_action/invoiceAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log(response.data);
          this.rows = response.data.mainData;
          this.sumAmount = response.data.sumPending;
          this.rowsExcel = response.data.excelData;
        });
    },
    updateInvoiceTable(start, stop) {
      const formData = new FormData();
      formData.append("txtAction", "getInvoice");
      formData.append("qSchool", this.userData.school_code);
      formData.append("lowerLimit", start);
      formData.append("upperLimit", stop);
      this.axios
        .post(this.$hostUrl + "php_action/invoiceAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log(response.data);
          this.rows = response.data.mainData;
          this.sumAmount = response.data.sumPending;
        })
        .finally(() => {});
    },
    toggleModal: function () {
      this.showModal = !this.showModal;
      location.reload();
    },
    openModal(data) {
      this.uploadFiles = [];
      this.darkModal = true;
      //FETCH SELECTED INVOICE
      this.axios
        .get(
          this.$hostUrl +
            "php_action/invoiceAPI.php?txtAction=getInvoice&qInvoice=" +
            data
        )
        .then((response) => {
          //console.log(response.data);
          this.selectedInvoice = response.data.mainData;
        })
        .finally(() => {});
    },
    closeModal() {
      //location.reload();
      this.darkModal = false;
    },
    updatePayment(event) {
      //const form = document.getElementById(event.target.id);
      const formData = new FormData();
      formData.append("txtAction", "update");
      formData.append("invoiceId", this.selectedInvoice.invoice_id);
      formData.append("userId", this.userData.user_id);
      formData.append("invoiceStatus", this.selectedInvoice.invoice_status);
      this.axios
        .post(this.$hostUrl + "php_action/invoiceAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log(response.data);
          if (response.data.updateState == "success") {
            this.responseContent = "บันทึกสำเร็จ";
            alert(this.responseContent);
            this.updateInvoiceTable(this.invoiceStart, this.invoiceStop);
          } else {
            alert(response.data.error);
            //location.reload();
          }
        })
        .finally(() => {});
    },
    deleteInvoice(data) {
      if (confirm("ต้องการลบใบแจ้งหนี้ " + data + " ใช่หรือไม่")) {
        const formData = new FormData();
        formData.append("txtAction", "delete");
        formData.append("invoiceId", data);
        formData.append("userId", this.userData.user_id);
        this.axios
          .post(this.$hostUrl + "php_action/invoiceAPI.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log(response.data);
            if (response.data.deleteState == "success") {
              this.responseContent = "บันทึกสำเร็จ";
              //alert(this.responseContent);
              this.updateInvoiceTable(this.invoiceStart, this.invoiceStop);
            } else {
              this.responseContent = response.data.error;
              alert(this.responseContent);
              //location.reload();
            }
          })
          .finally(() => {});
      } else {
        return false;
      }
    },
    handleImages(files) {
      this.uploadFiles = files;
      //console.log(this.uploadFiles);
    },
    onExport(level) {
      //FETCH ALL INVOICE
      let formData = new FormData();
      formData.append("txtAction", "getAllInvoiceData");
      formData.append("schoolCode", this.userData.user_address);
      formData.append("level", level);
      this.axios
        .post(this.$hostUrl + "php_action/invoiceAPI.php", formData)
        .then((response) => {
          this.allInvoice = response.data;
          this.invoiceStop = this.allInvoice.mainData[0].invoice_id;
          this.invoiceStart = this.invoiceStop - 2000;

          //console.log(this.rowsExcel);
          const dataWS = XLSX.utils.json_to_sheet(this.allInvoice.excelData);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, dataWS);
          XLSX.writeFile(wb, "export" + level + ".xlsx");
        });
    },
    searchInvoiceByStd() {
      //FETCH INVOICE INFO BY STD
      //alert(this.selectedStudent);
      let formData = new FormData();
      formData.append("txtAction", "getInvoice");
      formData.append("qStd", this.selectedStudent);
      formData.append("schoolCode", this.userData.user_address);
      this.axios
        .post(this.$hostUrl + "php_action/invoiceAPI.php", formData)
        .then((response) => {
          //console.log(response.data);
          this.rows = response.data.mainData;
          this.sumAmount = response.data.sumPending;
        });
    },
  },
};
</script>
<style scoped>
.ui-input-text {
  border-color: transparent;
}
</style>
