<template>
  <CCol lg="6">
    <CCard>
      <form id="formCreateQRbyClass" v-on:submit.prevent="createQRbyClass" method="POST">
        <CCardHeader class="bg-warning"><h3>สร้าง QR code เป็นรายชั้น</h3></CCardHeader>
        <CCardBody>
          <CRow>
            <CCol lg="6">
              <div class="relative w-full mb-3" v-if="userData">
                <label
                  class="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  รหัสระดับ
                </label>
                <input
                  type="number"
                  name="level_code"
                  class="form-control"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  required
                />
              </div>
            </CCol>


            <CCol lg="12" class="text-right" v-if="userData">
              <button class="btn btn-warning">generate</button>
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter> 
          <div>result:</div>
          <div>{{generateResult}}</div>
        </CCardFooter>
      </form>
    </CCard>


  </CCol>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
export default {
  components: {
    Datepicker,
    vSelect,
  },
  data() {
    return {
      userData: this.$cookies.get("user"),
      responseContent: null,
      showModal: false,
      modalSuccess: null,
      newCiteria: null,
      darkModal: false,
      qrString:null,
      generateResult:null
    };
  },
  mounted() {



  },
  methods: {
    createQRbyClass(event) {
      this.generateResult = "Loading.....";
      const form = document.getElementById(event.target.id);
      const formData = new FormData(form);
      formData.append("txtAction", "createQRByLevel");
      this.axios
        .post(this.$hostUrl + "php_action/qrAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log(response.data);
          this.generateResult = "created "+response.data.createdQR+" files";
        })
        .finally(() => {});
    },
    toggleModal: function () {
      this.showModal = !this.showModal;
      location.reload();
    },


  },
};
</script>
<style scoped>
.ui-input-text {
  border-color: transparent;
}
</style>
